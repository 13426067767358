@import "./variables.scss";

@function get-px($size) {
    @return ($size / 100vw) * 1920px;
  }

.footer{
    background: $theme-dark-green;
    position: relative;
    z-index: 1;
}
.footer::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('/white-mesh.png');
}
.footer_col{
    width: 25%;
    ul{
        list-style: none;
        padding: 0 0 10px 0;
        a{
            color: $theme-off-white;
            text-decoration: none;
        }
        span{
            font-weight: 500;
        }
    }
    img{
        height: auto;
        width: auto;
    }
    a:hover{
        color: $theme-main-color !important;
    }
}
.footer_col_wrap{
    padding-top: 50px;
}
.footer_col_last{
    text-align: right;
}
.footer_top{
    padding: 70px 0;
}
.footer_col_first{
    width: 35%;
    a:hover{
        color: $theme-main-color !important;
      }
}
.footer_col_second{
    width: 30%;
}
.footer_col_third{
    width: 35%;
    a:hover{
        color: $theme-main-color !important;
      }
}
.footer_bottom{
    border-top: 1px solid rgb(236 226 219 / 33%);
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a{
        padding-left: 5px;
        text-decoration: none;
    }
    a,p{
        color: $theme-off-white;
        font-size: $font-size-12;
        font-family: $font-quicksand;
        font-weight: 300 !important;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        li{
            a{
                padding: 0 10px;
                border-right: 1px solid $theme-off-white;
            }
        }
        li:last-child{
            a{
                border: 0;
            }
            
        }
        li:first-child{
            a{
                padding-left: 0;
            }
            
        }
    }
}
// .invisible_div{
//     height: 30vh;
// }
.bottom_map{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    img{
        width: 100%;
    }
}
.footer_main{
    position: relative;
    border-top: 1px solid rgb(236 226 219 / 33%);
}
.footer_social{
    display: flex;
    list-style: none;
    padding: 0;
    li{
        padding-right: 15px;
        img{
            width: 30px;
            height: 30px;
        }
    }
    a img{
        transform: scale(1);
        transition: all 0.5s;
    }
    a:hover img{
        transform: scale(0.9);
        transition: all 0.5s;
    }
}
@media (min-width: 1300px) and (max-width: 1600px) {
    .footer_bottom{
        a,p{
            font-size: $font-size-14;
        }
    }
}
@media (min-width: 1025px) and (max-width: 1300px) {
    .footer_bottom{
        a,p{
            font-size: get-px($font-size-10);
            font-weight: 300;
        }
    }
}
@media only screen and (min-width: 1025px) {
    
}
@media only screen and (max-width: 1025px) {
    .footer_bottom{
        a,p{
            font-size: get-px($font-size-12);
            font-weight: 300;
        }
    }
    .footer_col_first{
        width: 100%;
        padding-bottom: 20px;
        text-align: center;
    }
    .footer_col_second{
        width: 100%;
        padding-bottom: 20px;
        text-align: center;
    }
    .footer_col_third{
        width: 100%;
        p{
            text-align: center !important;
        }
    }
    .footer_col{
        width: 100%;
        ul{
            justify-content: center;
            text-align: center;
        }
    }
    .footer_bottom{
        padding-left: 5%;
        padding-right: 5%;
        ul{
            justify-content: center;
        }
    }
    .footer_col_last{
        img{
            display: block;
            margin: auto;
            width: 100px;
            height: auto;
        }
    }
    .footer_social{
        img{
            width: 30px;
            height: 30px;
        }
    }
    .invisible_div{
        height: 40px;
    }
    .footer_top{
        padding: 50px 0;
    }
}
@media (min-width: 600px) and (max-width: 1025px) {
    .invisible_div{
        height: 0px !important;
    }
}
@media only screen and (max-width: 600px) {
    
}