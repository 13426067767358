@import "./variables.scss";

@function get-px($size) {
    @return ($size / 100vw) * 1920px;
  }

.topmenu{
    padding-top: 1vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}
.main_menu{
    li{
        padding-right: 2.3vw;
        a{
            color: $theme-off-white;
            font-family: $font-quicksand;
            font-size: $font-size-14;
            text-transform: uppercase;
            text-decoration: none;
          }
    }
}
.mainmenu_logo{
    padding-right: 2vw;
}
.toggle_btn{
    border: 0;
    background: transparent;
    width: 40px;
    height: 40px;
    span{
        width: 30px;
        height: 3px;
        background-color: $theme-main-color;
        display: block;
        margin: 8px 0 8px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    span:nth-child(2){
        width: 20px;
        margin: 8px auto 8px 0;
    }
}
.scrollmenu{
    background: $theme-dark-green;
    padding: 1vw 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    z-index: 999999999;
    transform: translateY(-100%);
    transition: all 0.5s;
    .scrollmenu_logo{
        padding-right: 3vw;
    }
}
.footer_social{
    display: flex;
    list-style: none;
    padding: 50px 0 0 0;
    justify-content: center;
    li{
        padding: 0 10px;
    }
}
.menuOpen {
    right: 0 !important;
}
@media (min-width: 1300px) and (max-width: 1600px) {
    .main_menu{
        li{
            a{
                font-size: $font-size-16;
              }
        }
    }
}
@media (min-width: 1025px) and (max-width: 1300px) {
    .main_menu{
        li{
            padding-right: 2.3vw;
            a{
                font-size: get-px($font-size-11);
            }
        }
    }
}
@media only screen and (min-width: 1025px) {
    
}
@media only screen and (max-width: 1025px) {
    .main_menu{
        li{
            padding-right: 2.3vw;
            a{
                font-size: get-px($font-size-14);
            }
        }
    }
    .topmenu{
        padding-top: 20px;
    }
}
@media (min-width: 600px) and (max-width: 1025px) {
    .footer_social{
        padding: 50px 0 0 50px;
        justify-content: flex-start;
        li{
            padding: 0 20px 0 0;
        }
    }
    .topmenu{
        padding-top: 20px;
    }
}
@media only screen and (max-width: 600px) {
    .scrollmenu{
        padding: 5px 0;
        .scrollmenu_logo{
            img{
                height: 30px;
            }
        }
    }
    .toggle_btn{
        span{
            margin: 6px 0 6px auto;
        }
    }
}