//@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Martel+Sans:wght@200;300;400;600;700;800;900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=WindSong:wght@400;500&display=swap');
@import "./variables.scss";
@import './locomotive-scroll.css';

@function get-px($size) {
  @return ($size / 100vw) * 1920px;
}
@function get-vw($size) {
  @return ($size / 1920) * 100vw;
}

::-moz-selection { /* Code for Firefox */
  color: $theme-black;
  background: $theme-main-color;
}

::selection {
  color: $theme-black;
  background: $theme-main-color;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: inherit !important;
}
.slick-slider:not(.slick-initialized),
.slick-slider-related .products:not(.slick-initialized) { 
	display: none !important; 
}

body{
  background: $theme-dark-green !important;
}
.main-short-wrapper{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.main-wrapper{
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.middle-wrap{
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.small-wrap{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.lsmall-wrap{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.xsmall-wrap{
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}
.section-heading-large{
  font-size: $font-size-150;
  line-height: normal;
  font-family: $font-cormorant-garamond;
  font-weight: 400;
  position: relative;
  text-align: center;
}
.section-heading{
  font-size: $font-size-65;
  line-height: normal;
  font-family: $font-cormorant-garamond;
  font-weight: 400;
  position: relative;
}
.section-heading--white{
  color: #fff;
}
.section-heading--yellow{
  color: $theme-main-color;
}
.section-heading--cursive{
  font-family: $font-wind-song;
}
.heading-padding{
  padding-bottom: 1vw;
}
.heading-padding-large{
  padding-bottom: 2vw;
}
.paragraph{
  font-family: $font-quicksand;
  font-size: $font-size-20;
  line-height: $font-size-40;
  font-weight: 400;
}
.paragraph-small{
  font-family: $font-quicksand;
  font-size: $font-size-16;
  line-height: $font-size-35;
  font-weight: 400;
}
.paragraph-large{
  font-family: $font-quicksand;
  font-size: $font-size-30;
  line-height: $font-size-45;
  font-weight: 400;
}
.paragraph-large-garamond{
  font-family: $font-cormorant-garamond;
}
.paragraph-xsmall{
  font-family: $font-quicksand;
  font-size: $font-size-14;
  line-height: $font-size-30;
  font-weight: 400;
}
.paragraph--white{
  color: $theme-off-white;
}
.index-top{
  z-index: 1;
  position: relative;
}
.para-bold{
  font-weight: 600;
}
.all-caps{
  text-transform: uppercase;
}
.half-div{
  width: 47%;
}
.text-right{
  text-align: right;
}
.dark-green-bg{
  background-color: $theme-dark-green;
}
.dark-green-bg-trans{
  position: relative;
  background-image: url('/home/greenbg.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.dark-green-bg-trans::after{
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $theme-dark-green;
  opacity: 0.9;
}
.creative-heading{
  font-size: $font-size-30;
  line-height: normal;
  font-family: $font-cormorant-garamond;
}
.creative-heading-small{
  font-size: $font-size-16;
  line-height: normal;
  font-family: $font-quicksand;
}
.creative-heading-large{
  font-size: $font-size-40;
  line-height: normal;
  font-family: $font-cormorant-garamond;
}
.creative-heading--black,.section-heading--black,.paragraph--black{
  color: $theme-black;
}
.creative-heading--white{
  color: $theme-off-white;
}
.creative-heading--yellow,.paragraph--yellow,.large-title--yellow,.section-heading--yellow{
  color: $theme-main-color;
}
.large-title{
  font-size: $font-size-380;
  font-family:$font-cormorant-garamond;
  line-height: $font-size-250;
  height: get-vw(250);
  span{
    font-family:$font-martel;
  }
}
.section-padding-top{
  padding-top: get-vw(100);
}
.section-padding-bottom{
  padding-bottom: get-vw(100);
}
.section-high-padding-top{
  padding-top: get-vw(150);
}
.section-high-padding-bottom{
  padding-bottom: get-vw(150)
}
.white-mesh{
  background-image: url('/white-mesh.png');
  background-position: top;
  background-size: 100% auto;
}
.cream-mesh,.green-mesh {
  position: relative;
}
.black-bg{
  background: $theme-dark-green;
}
.green-mesh::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/white-mesh.png');
  background-position: top;
  background-size: auto;
  background-repeat: repeat;
  opacity: 1; 
}
.cream-mesh::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/cream-mesh.png');
  background-position: top;
  background-size: auto;
  background-repeat: repeat;
  opacity: 0.5; 
}
.next-btn-fixed{
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
  background: $theme-main-color;
  color: $theme-off-white;
  text-transform: uppercase;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  box-shadow: 6px 0px 6px rgb(0 0 0 / 16%);
}
.next-btn{
  font-family: $font-quicksand;
  font-size: $font-size-14;
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  border: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  width: max-content;
  justify-content: center;
  align-items: center;
}
.reservation-btn .next-btn--bottom{
  position: absolute;
  bottom: -10px;
}
.next-btn{
  .next-btn--bottom-square{
    transition: all 0.5s;
  }
  .next-btn--bottom-line{
    transition: all 0.5s;
  }
  .next-btn--text{
    transform: translateY(0px);
    transition: all 0.5s;
  }
}
.next-btn:hover{
  .next-btn--text{
    transform: translateY(-3px);
    transition: all 0.5s;
  }
  .next-btn--bottom-square{
    clip-path: none !important;
    transition: all 0.5s;
  }
  .next-btn--bottom-line{
    width: 40px;
    transition: all 0.5s;
  }
}
.next-btn--yellow{
  color: $theme-main-color;
}
.next-btn--white{
  color: #ffffff;
}
.next-btn--black{
  color: $theme-black;
}
button:focus,input:focus,select:focus{
  outline: 0 !important;
  box-shadow: none !important;
}
.next-btn--underline::after{
  content: "";
  height: 20px;
  width: 100%;
  background-image: url('/btn-line-yellow.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size:auto;
}
.next-btn--bottom{
  display: flex;
  align-items: center;
  padding-top: 5px;
  position: absolute;
  bottom: -10px;
  
  .next-btn--bottom-line{
    height: 2px;
    width: 30px;
   
    margin-top: 5px;
  }
  .next-btn--bottom-square{
    width: 10px; 
    height: 10px; 
    position: relative;
    transform: rotate(45deg); 
    transform-origin: bottom left;
    clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
    margin: -4px 10px 0 10px;
  }
}
.reserve-top{
  button{
    margin-top: -15px;
  }
}
.testimonial-slider{
  .next-btn--bottom{
    position: relative !important;
  }
}
.next-btn--black{
  .next-btn--bottom-line{
    background: $theme-black;
  }
  .next-btn--bottom-square{
    background-color: $theme-black;
  }
}
.next-btn--yellow{
  .next-btn--bottom-line{
    background: $theme-main-color;
  }
  .next-btn--bottom-square{
    background-color: $theme-main-color;
  }
}
.next-btn--white{
  .next-btn--bottom-line{
    background: #ffffff;
  }
  .next-btn--bottom-square{
    background-color: #ffffff;
  }
}
.reserve-btn{
  border: 0 !important;
  width: max-content;
  font-size: $font-size-15;
  height: get-vw(55);
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6vw;
  z-index: 1;
  letter-spacing: 0.7px;
  font-weight: 600;
}
.reserve-btn--yellow{
  background: $theme-main-color;
  color: $theme-black;
}
.awards-slider .slick-slide{
  padding: 0 2vw;
}
.awards-slider-inner .slick-slide{
  padding: 0 1vw;
}
.read-more-btn{
  text-decoration: none;
  padding: 10px 0 0 0;
  display: block;
  background: transparent;
  border: 0;
}
.recaptcha-wrap{
  padding-bottom: 2vw;
}
.error-msg-div{
  font-size: get-px($font-size-14);
  padding: 5px 20px;
  background: #f1caca;
  color: red;
  margin-top: 1vw;
}
.succes-msg-div{
  font-size: get-px($font-size-14);
  padding: 5px 20px;
  background: #c4e6b5;
  color: green;
  margin-top: 1vw;
}
.testimonial-modal{
  .modal-content{
    border-radius: 0;
    background: $theme-dark-green;
  }
  .modal-dialog{
    max-width: 55%;
    text-align: center;
  }
  .next-btn{
    margin: auto auto 20px auto;
  }
  .modal-body{
    padding: 3vw;
  }
  .modal-header{
    border: 0;
  }
  .btn-close{
    background-image: url('/close.png') !important;
    position: absolute;
    top: 1vw;
    right: 1vw;
  }
}
.before-icon{
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
}
.before-icon::before{
  content: "";
  height: 7px;
  width: 7px;
  background: #fff;
  margin-right: 15px;
  transform: rotate(45deg);
}
.the_content_div,.the_content_div p,.the_content_div a,.the_content_div a:hover,.the_content_div li{
  font-size: $font-size-16;
  line-height: $font-size-35;
  font-family: $font-quicksand !important;
  color: #fff;
}
.the_content_div h1,.the_content_div h2,.the_content_div h3,.the_content_div h4,.the_content_div h5,.the_content_div h6{
  font-size: $font-size-16;
  line-height: $font-size-35;
  color: $theme-main-color;
  text-transform: uppercase;
}
.the_content_div {
  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    li{
      position: relative;
      padding-left: 25px;
      width: 47%;
    }
    li::before{
      content: "";
      height: 7px;
      width: 7px;
      background: #fff;
      margin-right: 15px;
      transform: rotate(45deg);
      position: absolute;
      left: 0;
      top: get-vw(15);
    }
  }
}
.before-text{
  padding-left: 20px;
}
.bg-cream{
  background-color: $theme-off-white;
}
.bg-cream::before{
  background-size: auto;
  background-repeat: repeat;
}
@keyframes animateCloud {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}

@keyframes animateCloudleft {
  0% {
      transform: translateX(-100%);
  }
  100% {
      transform: translateX(100%);
  }
}

.cloud-wrapper .x1 {
  position: absolute;
  bottom: 65vh;
}

.cloud-wrapper .x2 {
  position: absolute;
  bottom: 60vh;
}

.cloud-wrapper .x3 {
  position: absolute;
  bottom: 65vh;
}

.cloud-wrapper .x4 {
  position: absolute;
  bottom: 70vh;
}

.cloud-wrapper img{
  opacity: 0.5;
}

.x1 {
  animation: animateCloudleft 60s linear infinite;
}

.x1 img {
  transform: scale(0.2);
}

.x2 {
  animation: animateCloud 150s linear infinite;
}

.x2 img {
  transform: scale(0.5);
}

.x3 {
  animation: animateCloud 180s linear infinite;
}

.x3 img {
  transform: scale(0.2);
}

.x4 {
  animation: animateCloudleft 100s linear infinite;
}

.x4 img {
  transform: scale(0.5);
}
.inner-dish-slider .slick-dots li button:before{
  left: 5px;
}
.testimonial-slider{
  .slick-dots{
    position: absolute;
    top: 70px;
    right: -5vw;
    display: flex !important;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
    width: 50px;
    li{
      margin: 5px 0;
    }
    li button{
      width: 10px !important;
    }
    li button:before{
      opacity: 1 !important;
      width: 10px !important; 
      height: 10px !important; 
      background-color: $theme-main-color;
      transform: rotate(45deg); 
      transform-origin: center;
      clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
      line-height: unset !important;
      font-size: unset !important;
    }
    li.slick-active button:before{
      clip-path: none !important;
    }
  }
}
.testimonial-opopup{
  background: $theme-dark-green;
  padding: 3vw;
}
.testimonial-modal .modal-content,.book-box .modal-content{
  max-width: 50%;
  margin: auto;
  padding: 0;
  width: 50%;
}
.testimonial-modal .modal-body{
  padding: 0;
}
.testimonial-modal .modal-header,.book-box .modal-header{
  // max-width: 50%;
  // width: 50%;
  // margin: auto;
  button{
    position: relative !important;
    right: 0 !important;
    top: 0 !important;
  }
}
.button-menu{
  padding-top: 4vw;
  img{
    padding-bottom: 0.5vw;
    height: auto;
    width: auto;
  }
}
.inner-dish-slider,.awards-slider-inner{
  .slick-dots{
    display: flex !important;
    justify-content: center;
    position: relative;
    padding-top: 2vw;
    z-index: 4;
    li{
      margin: 5px 3px !important;
    }
    li button{
      width: 10px !important;
    }
    li button:before{
      opacity: 1 !important;
      width: 10px !important; 
      height: 10px !important; 
      background-color: $theme-main-color;
      transform: rotate(45deg); 
      transform-origin: center;
      clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
      line-height: unset !important;
      font-size: unset !important;
    }
    li.slick-active button:before{
      clip-path: none !important;
    }
  }
}
.black-slider-dots{
  li button:before{
    background: $theme-black !important;
  }
}
.dish-slider {
  margin-right: 0vw;
  z-index: 2;
  img{
    width: 100%;
    height: auto;
  }
  .slick-dots{
    position: absolute;
    bottom: -40px;
    display: flex !important;
    justify-content: center;
    padding-left: 0;
    width: 29vw;
    li{
      width: 10px !important;
      margin: 0 10px;
    }
    li button{
      width: 10px !important;
    }
    li button:before{
      opacity: 1 !important;
      width: 10px !important; 
      height: 10px !important; 
      background-color: $theme-main-color;
      transform: rotate(45deg); 
      transform-origin: center;
      clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
      line-height: unset !important;
      font-size: unset !important;
    }
    li.slick-active button:before{
      clip-path: none !important;
    }
  }
}

.loading-section{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $theme-dark-green;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 99999999999;
  position: relative;
  div{
    align-items: center;
  }
}
.loading-gif{
  height: 5vw;
  width: auto;
  object-fit: scale-down;
}
.ent-slider,.offer-slider,.culinary-slider{
  .slick-arrow,.slick-arrow:hover{
    position: absolute;
    right: 1vw;
    left: auto;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    height: 50px;
    width: 40px;
    z-index: 2 !important;
  }
  .slick-next,.slick-next:hover{
    background-image: url('/w-right.png') !important;
    top: 50%;
  }
  .slick-prev,.slick-prev:hover{
    background-image: url('/w-left.png') !important;
    top: 60%;
  }
  .slick-prev:before, .slick-next:before{
    display: none;
  }
}
.offer-slider{
  padding-top: 30px;
  padding-bottom: 60px;
  margin: 0 -2vw;
  .slick-slide{
    padding: 0 2vw;
  }
}
.testimonial-slider{
  .next-btn{
    padding-bottom: 30px;
  }
  .carousel__text {
    transition:all 1s;
    opacity: 0;
    transform: translateY(100px);
  }
  .slick-current .carousel__text {
    transition:all 1s;
    opacity: 1;
    transform: translateY(0);
  }
}
.bottom_map{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  img{
    width: 100%;
    height: 31vh;
    object-fit: cover;
  }
}

.white-bg{
  background: $theme-off-white;
}
.city_view_box.activediv{
  .city_view_box_details{
      opacity: 1 !important;
  }
}
.cursor-add{
  cursor: pointer;
}


.cursor,.cursor-offer,.cursort{
  position: absolute;
  /* background: white;
  width: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 8px; */
  border-radius: 100%;
  z-index: 9999999;
  transition: 0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  0.2s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
  user-select: none;
  pointer-events: none;
  transform: scale(0.8);
}
.cursor::before,.cursor-offer::before,.cursort::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
.piu,.piut{
  display:none;
}
.piu.active,.piut.active{
  font-family: $font-quicksand;
  font-size: $font-size-14;
  display:block;
  background: $theme-main-color;
  color: $theme-black;
  font-weight: 600;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  position: relative;
  text-align: center;
  padding: 30px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.cursor.active,.cursor-offer.active,.cursort.active {
  opacity: 1;
  transform: scale(1);
}

.cursor.active::before,.cursor-offer.active::before,.cursort.active::before {
  opacity: 1;
}

.cursor-follower {
  position: absolute;
  background: $theme-main-color;
  opacity: 0.5;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  z-index: 1;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
  user-select: none;
  pointer-events: none;
  transform: translate(4px, 4px);
}

.cursor-follower.active {
  opacity: 0.3;
  transform: scale(0);
}

.next-btn-bottom-line{
  width: 100%;
  .next-btn--bottom-line{
    width: 45% !important;
    position: relative;
    background: transparent;
  }
  .next-btn--bottom-line::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    background: $theme-main-color;
    width: 0;
    transition: width 0.5s ease;
  }
  .next-btn--bottom-line:first-child:after{
    left: 0;
    right: auto;
  }
  .next-btn--bottom-line:last-child::after{
    right: 0;
    left: auto;
  }
}
.activediv{
  .next-btn--bottom-line:first-child:after{
    width: 100%;
    transition: width 0.5s ease;
  }
  .next-btn--bottom-line:last-child::after{
    width: 100%;
    transition: width 0.5s ease;
  }
}
.corner_bottom,.corner_bottom_right{
  width: 0;
  height: 0;
  transition: all 5s;
}
.corner_top{
  width: 0;
  height: 0;
  transition: all 5s;
}
.corner_bottom.is-inview,.corner_top.is-inview,.corner_bottom_right.is-inview{
  width: 6vw;
  height: 6vw;
  transition: all 5s;
}
.nav-item.active{
  a{
    color: $theme-main-color;
  }
}
.nav-item a{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.next-btn-bottom-menu,.next-btn-bottom-menu:hover{
  .next-btn--bottom{
    padding: 0;
  }
  .next-btn--bottom-line{
    flex-grow: 1;
    width: max-content;
  }
  .next-btn--bottom-square{
    margin: -4px 7px 0 4px;
  }
}

.next-btn-bottom-menu{
  .next-btn--bottom-line{
    position: relative;
    background: transparent;
  }
  .next-btn--bottom-line::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    background: $theme-main-color;
    width: 0;
    transition: width 0.5s ease;
  }
  .next-btn--bottom-line:first-child:after{
    right: 0;
    left: auto;
  }
  .next-btn--bottom-line:last-child::after{
    left: 0;
    right: auto;
  }
  .next-btn--bottom-square{
    opacity: 0;
    transition: width 0.5s ease;
  }
}
.navbar {
  a:hover{
    .next-btn--bottom-line:first-child:after{
      width: 100% !important;
      transition: width 0.5s ease;
    }
    .next-btn--bottom-line:last-child::after{
      width: 100% !important;
      transition: width 0.5s ease;
    }
    .next-btn--bottom-square{
      opacity: 1;
      transition: width 0.5s ease;
    }
  }
  
}
.culinary-slider{
  img{
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
}
.culinary-slider,.ent-slider{
  margin-bottom: -6px;
}
.display-flex-sec{
  display: flex;
}

.animated-section-heading{
  transform: translate3d(5px, 40px, 0);
			opacity: 0;
			transition: transform 0ms, opacity 0ms, -webkit-transform 0ms;
}
.animated-section-heading.animated{
  transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1), opacity 1500ms cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 1500ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.black-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
}
.menu-nav-slide{
    background: $theme-dark-green;
    position: fixed;
    width: 100%;
    right: -100%;
    opacity: 1;
    height: 100vh;
    z-index: 99;
    top: 0;
    background-repeat: no-repeat;
    background-position: 23% 10%;
    visibility: visible;
    overflow: auto;
    padding-top: 100px;
    transition: right 0.3s ease;
}

.ham.open span:nth-child(1){
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}
.ham.open span:nth-child(2){
  opacity: 0;
}
.ham.open span:nth-child(3){
  -webkit-transform: translateY(-12px) rotate(-45deg);
  -ms-transform: translateY(-12px) rotate(-45deg);
  -o-transform: translateY(-12px) rotate(-45deg);
  transform: translateY(-12px) rotate(-45deg);
}
.main-div-body{
  min-height: 100vh;
  background: $theme-dark-green;
}
// .empty-page{
//   min-height: 100vh;
//   background: $theme-dark-green;
//   padding-top: 30vh;
//   text-align: center;
//   color: $theme-off-white;
//   padding-top: 10vh;
// }
.insta-link{
  text-decoration: none;
}
.z-top{
  z-index: 2;
  position: relative;
}
.last-sec-line{
  border-bottom:1px solid rgb(255 255 255 / 24%);
}
.insta-left{
  width: 60%;
}
.insta-right{
  width: 40%;
  text-align: right;
}
.insta-widget{
  padding-top: 1vw;
  a{
    height: 100%;
    width: 100%;
  }
  img,video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.inner-banner{
  img{
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  .inner-banner-text{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }
}
.inner-banner-text-wrap{
  padding-top: get-vw(150);
  text-align: center;
}
.inner-banner::before{
  z-index: 2;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%);
}
.inner-banner-offers::before{
  height: 100% !important;
  background: rgb(0 0 0 / 70%) !important;
}
.inner-banner-offers{
  .page-inner-carousel{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .inner-banner-offers-text{
    position: relative !important;
    padding-bottom: 100px;
    min-height: 100vh;
  }
}
.inner-banner::after{
  z-index: 2;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  background: linear-gradient(0deg, #111D22 14.46%, rgba(12, 54, 49, 0.00) 100%);
}
.page-inner-carousel{
  .carousel-control-prev,.carousel-control-next,.carousel-indicators{
    display: none;
  }
}
.bg-dark-green{
  background-color: $theme-dark-green;
}
.short-div{
  width: 40%;
}
.long-div{
  width: 50%;
}
.inner-dish-slider{
  margin: 0 -1vw;
  .slick-slide{
    padding: 0 1vw;
  }
}
.offer-tabs{
  display: flex;
  justify-content: center;
  border: 0 !important; 
  padding-bottom: 3vw;
  button,button:hover{
    border: 0 !important;
    border-radius: 0;
    background: transparent !important;
    color: #fff;
    font-size: $font-size-20;
    font-family: $font-quicksand;
    font-weight: 500;
    position: relative;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-around;
    padding: 0 2vw !important;
    text-align: left;
  }
  button.active{
    color: $theme-main-color !important;
  }
  button::before{
    content: "";
    height: 7px;
    width: 7px;
    background: #fff;
    margin-right: 15px;
    transform: rotate(45deg);
  }
  button.active::before{
    background: $theme-main-color;
  }
}
.tag-wrap-inner{
  div{
    margin-right: 10px;
  }
}

.gallery-div-wrap{
  column-count: 3;
  gap: 1vw;
  padding-top: 2vw;
  .gallery-div{
    page-break-inside: avoid;
    break-inside: avoid-column;
    display: table;
    width: 100%;
    margin-bottom: 1vw;
    overflow: hidden;
    cursor: pointer;
    img{
      height: auto;
      width: 100%;
    }
  }
}
.modal-backdrop{
  background: rgb(0 0 0 / 100%) !important;
}
.modal-content,.modal-header{
 background: transparent !important;
 border: 0 !important;
}
.prev-btn-modal,.next-btn-modal{
  position: absolute;
  z-index: 1;
  background-color: transparent !important;
  border: 0 !important;
  img{
    height: auto;
    width: auto;
  }
}
.prev-btn-modal{
  left: 0;
}
.next-btn-modal{
  right: 0;
}
.modal-dialog{
  max-width: 95% !important; 
}
.mySlides{
  width: 100%;
  display: flex;
  justify-content: center;
  img{
    height: 90vh;
    width: auto;
  }
}
.modal-header{
  button{
    position: absolute !important;
    top: 1vw;
    right: 2.5%;
  }
}
.modal-header .btn-close{
  background: url('/close.png') !important;
}
.text-center{
  text-align: center;
}
.video-box{
  width: 33.3%;
  padding: 1vw;
  cursor: pointer;
  .video-box-inner{
    position: relative;
  }
  img{
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
  .video-box-inner::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('/play-button.png');
    background-repeat: no-repeat;
    background-position: center;
  }
}
.iframe-div{
 
  iframe{
    height: 80vh;
    width: 70%;
  }
  .codewrap{
    display: flex;
    justify-content: center;
  }
}
.contact-ul{
  list-style: none;
  padding: 0;
  a:hover{
    color: $theme-main-color !important;
  }
  img{
    height: auto;
    width: auto;
    margin-top: 5px;
  }
  li{
    display: flex;
    align-items: flex-start;
    padding-bottom: 1vw;
    div,a{
      padding-left: 10px;
      display: block;
      text-decoration: none;
    }
  }
}
.form-wrap{
  .form-col-half{
    width: 48%;
  }
  .form-col-third{
    width: 31%;
  }
  .form-col-full{
    width: 100%;
  }
  .dropdown-div{
    width: 20%;
  }
  .input-div{
    width: 73%;
  }
  input[type="text"],input[type="date"],input[type="number"],input[type="time"],input[type="tel"],input[type="email"],select,
  .react-datepicker-wrapper,.react-time-picker{
    height: 40px;
    width: 100%;
    border: 0;
    background: transparent;
    font-size: $font-size-16;
    border-bottom: 1px solid $theme-black;
  }
  .react-time-picker__wrapper{
    border: 0 !important;
  }
  .form-row{
    padding-bottom: 2vw;
  }
  label{
    color: $theme-black;
    font-size: $font-size-16;
    text-transform: capitalize;
    span{
      color: #F00;
    }
  }
  .checked-lable{
    padding-bottom: 1vw;
    align-items: center;
    display: flex;
    text-transform: none !important;
    p{
      padding-left: 10px;
    }
  }
  button{
    padding: 0 2vw;
  }
  .row-icon {
    img{
      position: absolute;
      right: 0;
      top: 30px;
      z-index: -1;
      // display: none;
    }
  }
}
.downbtn-bottom{
  img{
    margin-bottom: 15px;
  }
}
.react-time-picker__wrapper{
  button{
    visibility: hidden !important;
  }
}
.playbtn{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  img{
    height: 40px;
    width: auto;
  }
}
.playbtnalum{
  padding: 2vw;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  img{
    height: 30px;
    width: auto;
  }
}
.react-datepicker{
  background: #fff;
  font-family: $font-quicksand !important;
  font-size: $font-size-16;
  border: 0 !important;
  .react-datepicker__triangle{
    stroke: #fff !important;
    fill: #ffffff !important;
    color: #f0f0f0 !important;
  }
  .react-datepicker__header{
    border: 0 !important;
    background: #fff;
  }
  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover,
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background: $theme-dark-green-main !important;
  }
  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    border-color: $theme-orange;
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background: $theme-orange !important;
    color: #fff !important;
  }
}

input[type=checkbox] {
  position: relative;
  visibility: hidden;
  -moz-appearance: initial;
  display: inline-block;
  width: 30px;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
input[type=checkbox]:checked:before {
  border: 0 !important;
}
input[type=checkbox]:before, input[type=checkbox]:checked:after {
  width: 15px;
  height: 15px;
  top: 0;
  right: 0;
  bottom: 0;
  position: relative;
  content: "";
  visibility: visible;
  border: 1px solid #595959 !important;
}
input[type=checkbox]:before {
  left: 0;
  background-color: transparent;
  display: inline-block;
}
input[type=checkbox]:checked:after {
  margin: auto;
  left: -15px;
  font-size: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: FontAwesome;
  background-image: url(/tick.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 50%;
  background-color: $theme-dark-green-main;
  display: inline-block;
  border: 1px solid $theme-dark-green-main !important;
}
input[type=checkbox]:before, input[type=checkbox]:checked:after {
  width: 15px;
  height: 15px;
  top: 0;
  right: 0;
  bottom: 0;
  position: relative;
  content: "";
  visibility: visible;
  border: 1px solid #595959 !important;
}
.error-msg{
  color: #b61d1d !important;
  font-size: $font-size-12;
}
.contact-map{
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.city-btn{
  bottom: 0;
}

.not-found-img{
  height: auto;
  width: 60%;
}
.sitemap-ul{
  width: max-content;
  margin: auto;
  display: block !important;
  a{
    text-decoration: none;
  }
  a:hover{
    color: $theme-main-color;
  }
  li{
    width: 100% !important;
  }
}


.instagrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 25vw);
  grid-column-gap: 0.5vw;
  grid-row-gap: 0.5vw;

  a{
    display: block;
    position: relative;
  }
  a::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(0 0 0 / 70%);
    transition: all 0.5s;
    opacity: 0;
  }
  a:hover::after{
    opacity: 1;
    transition: all 0.5s;
  }

  }
  
  .instagrid .div1 { grid-area: 1 / 1 / 3 / 3; }
  .instagrid .div2 { grid-area: 1 / 3 / 2 / 4; }
  .instagrid .div3 { grid-area: 1 / 4 / 2 / 5; }
  .instagrid .div4 { grid-area: 2 / 3 / 3 / 4; }
  .instagrid .div5 { grid-area: 2 / 4 / 3 / 5; }

.form-para a,.form-para a:hover{
  color: $theme-dark-green;
}



@media (min-width: 1300px) and (max-width: 1600px) {
  .paragraph-small{
    font-size: $font-size-18;
    line-height: $font-size-35;
  }
  .paragraph-xsmall{
    font-size: $font-size-18;
    line-height: $font-size-35;
  }
  .next-btn{
    font-size: $font-size-18;
  }
  .creative-heading-small {
    font-size: $font-size-18;
  }
}
@media (min-width: 1025px) and (max-width: 1300px) {
  .paragraph-small{
    font-size: get-px($font-size-13);
    line-height: get-px($font-size-25);
  }
  .paragraph-xsmall{
    font-size: get-px($font-size-13);
    line-height: get-px($font-size-25);
  }
  .middle-wrap{
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  .next-btn{
    font-size: get-px($font-size-11);
  }
}
@media only screen and (min-width: 1025px) {
  .map-details{
    text-align: center !important;
    p,a,a:hover,h2{
      color: #000;
      font-size: $font-size-14;
    }
    .gm-style .gm-style-iw-c{
      background: $theme-main-color;
      padding: 0 2vw 1vw 2vw !important;
    }
    .gm-style-iw-d{
      overflow: auto !important;
    }
    .gm-style .gm-style-iw-tc::after{
      background: $theme-main-color !important;
    }
    img{
      height: 2vw;
      width: auto;
      object-fit: scale-down;
    }
  }
  .google_map_emb{
    height: 100%;
    #map{
      height: 100% !important;
      width: 100% !important;
    }
    .codewrap{
      height: 100%;
    }
    iframe{
      width: 100%;
      height: 100%;
    }
  }
  .ex-small{
    font-size: $font-size-12 !important;
  }
  .book-box{
    iframe{
      width: 100%;
      height: 80vh;
    }
  }
  
  .menu-logo-wrap-div{
    display: flex;
  }
    .offer-inner-slider{
      .slick-arrow{
        display: none !important;
      }
    }
    .menu-nav-slide,.black-overlay{
      display: none !important;
    }
    .header-scrolled{
      .scrollmenu{
          transform: translateY(0%);
          transition: all 0.5s;
      }
    }
    .mobileonly_el{
      display: none !important;
    }
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $theme-black;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .dish-slider .slick-slide img{
      transform: scale(0.5);
      opacity: 0;
      transition: all 0.5s;
    }
    .dish-slider .slick-slide{
      height: 35vw;
    }
    .dish-slider .slick-active.slick-slide img{
      transform: scale(1);
      opacity: 1;
      transition: all 0.5s;
      height: 29vw;
      width: 29vw;
    }
    .dish-slider .slick-active.slick-slide + .slick-active.slick-slide .dine-title,.dish-slider .slick-slide .dine-title{
      transform: translate3d(0px, 100px, 0);
      opacity: 0;
     // visibility: hidden;
      transition: transform 0ms, opacity 0ms, -webkit-transform 0ms;
    }
    .dish-slider .slick-active.slick-slide .dine-title{
      transition-delay: 1s;
      transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1), opacity 1500ms cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 1500ms cubic-bezier(0.19, 1, 0.22, 1);
      transform: translate3d(0, 0, 0);
      //visibility: visible;
      opacity: 1;
    }
    .dish-slider .slick-active.slick-slide + .slick-active.slick-slide img{
      transform: scale(0.4);
      transform-origin: right;
      opacity: 1;
      transition: all 0.5s;
    }
    .mobile_el{
      display: none !important;
    }
    .in_tab_el{
      display: none !important;
    }
}
@media only screen and (max-width: 1025px) {
  .inner-banner-offers::before{
    height: 100% !important;
    background: rgb(0 0 0 / 50%) !important;
  }
  .inner-banner-offers .inner-banner-offers-text {
    min-height: unset;
}
  .inner-banner-offers{
    .page-inner-carousel{
      position: relative !important;
    }
  }
  .testimonial-modal .modal-content,.book-box .modal-content{
    max-width: 100%;
    margin: auto;
    padding: 0;
    width: 100%;
  }
  .testimonial-modal .modal-header,.book-box .modal-header{
    max-width: 100%;
    width: 100%;
    margin: auto;
    button{
      position: relative !important;
      right: 0 !important;
      top: 0 !important;
    }
  }
  .map-details{
    text-align: center !important;
    p,a,a:hover,h2{
      color: #000;
      font-size: $font-size-14;
    }
    img,a{
      display: block;
    }
    p,h2{
      display: none !important;
    }
    .gm-style .gm-style-iw-c{
      background: $theme-main-color;
      padding: 0 20px 20px 20px !important;
    }
    .gm-style-iw-d{
      overflow: auto !important;
    }
    .gm-style .gm-style-iw-tc::after{
      background: $theme-main-color !important;
    }
    img{
      height:30px;
      width: auto;
      object-fit: scale-down;
    }
  }
  .ex-small{
    font-size: 10px !important;
  }
  .book-box{
    iframe{
      width: 100%;
      height: 80vh !important;
    }
    .modal-body{
      padding: 0 !important;
    }
  }
  .not-found-img{
    width: 100%;
    height: auto;
  }
  .testimonial-opopup {
    padding: 30px 20px;
    max-width: 100%;
  }
  .the_content_div,.the_content_div p,.the_content_div a,.the_content_div a:hover,.the_content_div li{
    font-size: get-px($font-size-16);
    line-height: get-px($font-size-30);
  }
  .the_content_div h1,.the_content_div h2,.the_content_div h3,.the_content_div h4,.the_content_div h5,.the_content_div h6{
    font-size: get-px($font-size-18);
    line-height: get-px($font-size-30);
  }
  .the_content_div ul li {
    width: 100%;
  } 
  .the_content_div ul li::before {
    top: 10px;
}
  .reserve-btn{
    font-size: get-px($font-size-15);
    height: 45px;
  }
  .text-left-mobile{
    text-align: left !important;
  }

  .offer-inner-slider{
    .slick-arrow{
      bottom: -75px !important;
    }
  }
  .offer-tabs{ 
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    li{
      width: 50%;
    }
    button,button:hover{
      font-size: get-px($font-size-16);
      padding: 0 15px 20px 15px !important;
    }
  }
  .lsmall-wrap{
    width:90%;
  }
  .inner-banner .inner-banner-text {
    padding: 0 5%;
  } 
  .section-heading-large{
    font-size: get-px($font-size-35);
  }
  .heading-padding {
    padding-bottom: 20px;
}

  .heading-padding-mobile{
    padding-bottom: 20px;
  }
  .button-menu {
    padding-top: 50px;
}
  .inner-dish-slider{
    .slick-dots{
      padding-top: 20px;
    }
  }

  .insta-left{
    width: 100%;
  }
  .insta-right{
    width: 100%;
    text-align: center;
  }
  .scrollmenu{
    background: $theme-dark-green !important;
  }
  .menu-nav-slide{

    nav{
      width: 100%;
    }
    ul{
      width: 100%;
    }
    li{
      padding: 10px;
    }
    li.active{
      background: $theme-dark-green-main;
    }
    .next-btn-bottom-menu{
      display: none !important;
    }
  }
  .header-scrolled{
    .scrollmenu{
        transform: translateY(0%);
        transition: all 0.5s;
    }
  }
  .cursor,.cursor-follower{
    display: none !important;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  .desktop_el{
    display: none !important;
  }
  .heading-padding-large {
    padding-bottom: 30px;
  }
  .main-wrapper{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .middle-wrap{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .small-wrap{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .xsmall-wrap{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .section-heading{
    font-size: get-px($font-size-30);
    text-align: center;
  }
  .creative-heading{
    font-size: get-px($font-size-20);
    line-height: get-px($font-size-30);
    text-align: center;
  }
  .creative-heading-small{
    font-size: get-px($font-size-16);
    text-align: center;
  }
  .creative-heading-large{
    text-align: center;
    font-size: get-px($font-size-22);
  }
  .paragraph,.paragraph-large{
    text-align: center;
    font-size: get-px($font-size-14);
    line-height: get-px($font-size-25);
  }
  .paragraph-small{
    text-align: center;
    font-size: get-px($font-size-14);
    line-height: get-px($font-size-27);
  }
  .paragraph-xsmall{
    font-size: get-px($font-size-14);
    line-height: get-px($font-size-25);
  }
  .large-title{
    font-size: $font-size-420;
    line-height: $font-size-250;
    height: $font-size-250;
    text-align: center;
  }
  .next-btn{
    font-size: get-px($font-size-13);
  }
  .piu.active,.piut.active{
    font-size: get-px($font-size-14);
  }
  .loading-section{
    img{
      width: 70%;
    }
  }
  .section-padding-bottom,.section-high-padding-bottom {
    padding-bottom: 70px;
  }
  .section-padding-top,.section-high-padding-top {
    padding-top: 70px;
  }
  .next-btn{
    margin-left: auto;
    margin-right: auto;
  }
  .half-div {
    width: 100%;
  }
  .dish-slider .slick-dots {
    position: relative;
    bottom: 0;
    width: 100%;
    padding: 20px 0;
  }
  .dish-slider img {
    width: 80%;
    height: auto;
    margin: auto;
  }
  .next-btn.mobile_el{
    padding-top: 30px;
  }
  .corner_bottom.is-inview,.corner_top.is-inview,.corner_bottom_right.is-inview{
    width: 70px;
    height: 70px;
    transition: all 5s;
  }
  .dish-slider {
    .carousel__text {
      transition:all 1s;
      opacity: 0;
      transform: translateY(50px);
    }
    .slick-current .carousel__text {
      transition:all 1s;
      opacity: 1;
      transform: translateY(0);
    }
  }
  .recaptcha-wrap {
    padding-bottom: 20px;
}
}
@media (min-width: 600px) and (max-width: 1025px) {
  .prev-btn-modal img, .next-btn-modal img {
    height: 40px;
    width: 40px;
}
  .google_map_emb{
    height: 100%;
    #map{
      height: 100% !important;
      width: 100% !important;
    }
    .codewrap{
      height: 100%;
    }
    iframe{
      width: 400px;
      height: 100%;
    }
  }

  .reserve-top button {
    margin-top: 0;
}
  .contact-ul li {
    align-items: baseline;
    padding-bottom: 20px;
}
  .error-msg{
    font-size: get-px($font-size-12);
  }
  .form-wrap{
    .checked-lable{
      padding-bottom: 20px;
    }
    button{
      padding: 0 30px;
    }
    p,h6{
      text-align: left !important;
    }
    .form-row{
      padding-bottom: 30px;
    }
    label,input[type="text"],input[type="date"],input[type="number"],input[type="time"],input[type="tel"],input[type="email"],select,
    .react-datepicker-wrapper,.react-time-picker{
      font-size:get-px($font-size-14);
    }
  }
  .inner-banner-text-wrap {
    padding-top: 150px;
}
  .offer-tabs {
    justify-content: flex-start;
}
  .video-box img {
    height: 250px;
}
  .video-box {
    width: 50%;
    padding: 20px;
}
  .iframe-div{
    iframe{
      height: 400px;
      width: 95%;
    }
  }
  .modal-header button {
    top: 0;
    right: 3%;
}
  .gallery-div-wrap{
    column-count: 2;
    gap: 20px;
    padding-top: 20px;
    .gallery-div{
      margin-bottom:20px;
    }
  }
  .mySlides{
    img{
      height: auto;
      width: 100%;
    }
  }
 .middle-wrap-single{
    width: 90% !important;
  }
  .reserve-btn{
    font-size: get-px($font-size-15);
    height: 45px;
  }
  .half-div-tab-full{
    width: 100% !important;
  }
  .offer-tabs{ 
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li{
      width: auto;
    }
  }
  .lsmall-wrap{
    width:80%;
  }
  .inner-banner .inner-banner-text {
    padding: 0 5%;
  } 
  .section-heading-large{
    font-size: get-px($font-size-65);
  }
  .tab-full-div{
    width: 100% !important;
  }
  .inner-banner img{
    height: 600px;
  }
  .short-div {
    width: 45%;
}
  .short-div-low-padding{
    padding-bottom: 50px !important;
  }
  .insta-widget{
    padding-top: 15px;
  }

  .header.open{
    .scrollmenu{
      transform: translateY(0%);
      transition: all 0.5s;
    }
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
  }
  .menu-nav-slide {
    width: 50%;
    right: -50%;
    padding-top: 100px;
    padding-bottom: 50px;
    background: $theme-dark-green;
    ul,nav{
      // width: 50%;
      // margin-left: auto;
      z-index: 1;
      position: relative;
      ul{
        width: 100%;
      }
    }
    li{
      text-align: left;
      padding-left: 50px;
      a{
        align-items: flex-start;
      }
    }
  }
  // .menu-nav-slide::after{
  //   content: "";
  //   position: fixed;
  //   top: 0;
  //   bottom: 0;
  //   left: 50%;
  //   right: 0;
  //   background: $theme-dark-green;
  // }
  .mobileonly_el{
    display: none !important;
  }
  .padding-right{
    padding-right: 5vw;
  }
  .not_in_tab_el{
    display: none !important;
  }
  .section-heading{
    font-size: get-px($font-size-40);
    text-align: center;
  }
  .creative-heading{
    font-size: get-px($font-size-22);
    line-height: get-px($font-size-30);
    text-align: center;
  }
  .creative-heading-small{
    font-size: get-px($font-size-18);
    text-align: center;
  }
  .creative-heading-large{
    text-align: center;
    font-size: get-px($font-size-22);
  }
  .paragraph,.paragraph-large{
    text-align: center;
    font-size: get-px($font-size-16);
    line-height: get-px($font-size-30);
  }
  .paragraph-small{
    text-align: center;
    font-size: get-px($font-size-16);
    line-height: get-px($font-size-30);
  }
  .paragraph-xsmall{
    font-size: get-px($font-size-14);
    line-height: get-px($font-size-25);
  }
  .next-btn {
    font-size: get-px($font-size-16);
  }
  .offer-slider {
    padding-top: 30px;
    padding-bottom: 100px;
    margin: 0;
  }
  .ent-slider{
    .slick-next,.slick-next:hover{
      top: 50%;
      right: 2% !important;
    }
    .slick-prev,.slick-prev:hover{
      top: 50%;
      left: 2% !important;
    }
  }
  .sin-slider .slick-prev,.sin-slider .slick-prev:hover{
    left: -4% !important;
  }
  .sin-slider .slick-next,.sin-slider .slick-next:hover{
      right: -4% !important;
  }
  .offer-slider{
    .slick-next,.slick-next:hover{
      top: auto !important;
      bottom: 0px;
      right: 40% !important;
    }
    .slick-prev,.slick-prev:hover{
      top: auto !important;
      bottom: 0px;
      left: 40% !important;
    }
  }
  .culinary-slider{
    .slick-next,.slick-next:hover{
      top: 50%;
      right: 2% !important;
    }
    .slick-prev,.slick-prev:hover{
      top: 50%;
      left: 2% !important;
    }
  }
  .testimonial-slider .slick-dots {
    position: relative;
    top: auto;
    right: auto;
    display: flex !important;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .middle-wrap {
    width: 85%;
  }
  .large-title {
    font-size: $font-size-380;
    line-height: $font-size-250;
    height: $font-size-250;
    text-align: center;
  }
  .offer-slider-sec{
    width: 95%;
  }
  .main-wrapper-head{
    justify-content: unset !important;
    align-items: flex-start !important;
    .next-btn--bottom{
      padding-top: 0;
    }
    .next-btn{
      padding-right: 50px;
    }
  }
  .testimonial-modal .modal-dialog {
    max-width: 80%;
  }
  .read-more-btn{
    margin: auto;
  }
  .offer-slider .creative-heading{
    height: 115px;
  }
  .long-div .paragraph,.long-div .section-heading,.long-div .creative-heading-small,
  .short-div .paragraph,.short-div .section-heading,.short-div .creative-heading-small{
    text-align: left !important;
  }
  .tab-text-left{
    text-align: left !important;
  }
  .creative-heading {
    font-size: 25px;
    line-height: 35px;
}
.half-div {
  width: 48%;
}
}
.tag-wrap-div{
  padding-bottom: 10px;
  div{
    margin:5px;
  }
}
@media (min-width: 991px) and (max-width: 1025px){

  .offer-slider-sec {
    width: 85%;
  }
  .xsmall-wrap {
    width: 60%;
  }
  .inner-banner-offers{
    .inner-banner-offers-text{
      padding-bottom: 50px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .inner-banner-offers{
    .inner-banner-offers-text{
      padding-bottom: 50px;
    }
  }
  .prev-btn-modal,.next-btn-modal{
    bottom: -55px;
  }
  .prev-btn-modal{
    left: 30%;
  }
  .next-btn-modal{
    right: 30%;
  }
  .google_map_emb{
    height: 100%;
    #map{
      height: 400px !important;
      width: 100% !important;
    }
    .codewrap{
      height: 100%;
    }
    iframe{
      width: 400px;
      height: 100%;
    }
  }
  .google_map_emb iframe {
    width: 100% !important;
    height: 300px;
  }
  .error-msg{
    font-size: get-px($font-size-12);
  }
  .footer-social-con{
    justify-content: center;
    li{
      padding: 20px 10px 0 10px;
    }
  }
  .contact-details{
    ul li div{
      text-align: left !important;
    }
    ul li{
    padding-bottom: 20px;
    }
    
  }
  .contact-map{
    padding-top: 50px;
  }
  .form-wrap{
    .checked-lable{
      padding-bottom: 20px;
    }
    button{
      padding: 0 30px;
    }
    p,h6{
      text-align: left !important;
    }
    .form-row{
      padding-bottom: 0px;
    }
    label,input[type="text"],input[type="date"],input[type="number"],input[type="time"],input[type="tel"],input[type="email"],select,
    .react-datepicker-wrapper,.react-time-picker{
      font-size:get-px($font-size-14);
    }
    .form-col-half{
      width: 100%;
      padding-bottom: 30px;
    }
    .form-col-third{
      width: 100%;
      padding-bottom: 30px;
    }
    .form-col-full{
      width: 100%;
      padding-bottom: 30px;
    }
    .dropdown-div{
      width: 30%;
    }
    .input-div{
      width: 68%;
    }
    input[type="text"],input[type="date"],input[type="number"],input[type="time"],input[type="tel"],input[type="email"],select,
    .react-datepicker-wrapper,.react-time-picker{
      height: 40px;
      width: 100%;
      border: 0;
      background: transparent;
      border-bottom: 1px solid $theme-black;
    }
  }
  .inner-banner-text-wrap {
    padding-top: 100px;
}
  .offer-tabs {
    justify-content: flex-start;
}
  .video-box img {
    height: 250px;
}
  .video-box {
    width: 100%;
    padding: 20px 0;
}
  .iframe-div{
    iframe{
      height: 300px;
      width: 95%;
    }
  }
  .modal-header button {
      top: 0;
      right: 8%;
  }
  .modal-dialog {
    max-width: 100% !important;
}
  .mySlides{
    img{
      height: auto;
      width: 95%;
    }
  }
  .gallery-div-wrap{
    column-count: 1;
    gap: 0;
    padding-top: 0px;
    .gallery-div{
      margin-bottom:20px;
    }
  }

  .the_content_div ul li {
    width: 100%;
  } 
  .the_content_div ul li::before {
    top: 10px;
}
  .reserve-btn{
    font-size: get-px($font-size-15);
    height: 45px;
  }
  .the_content_div,.the_content_div p,.the_content_div a,.the_content_div a:hover,.the_content_div li{
    font-size: get-px($font-size-14);
    line-height: get-px($font-size-25);
  }
  .the_content_div h1,.the_content_div h2,.the_content_div h3,.the_content_div h4,.the_content_div h5,.the_content_div h6{
    font-size: get-px($font-size-15);
    line-height: get-px($font-size-25);
  }
  .inner-banner img{
    height: 400px;
  }
  .half-div-mobile-padding{
    padding-bottom: 50px;
  }
  .short-div-low-padding{
    padding-bottom: 30px !important;
  }
  .short-div,.long-div{
    width: 100%;
  }
  .insta-widget{
    padding-top: 15px;
  }
  .testimonial-modal .modal-dialog {
    max-width: 100%;
  }
  .offer-slider .creative-heading{
    height: 90px;
  }
  .read-more-btn{
    margin: auto;
  }
  .scroll-down-line{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .in_tab_el,.tab_desktop_el{
    display: none !important;
  }
  .offer-slider {
    padding-top: 30px;
    padding-bottom: 100px;
    margin: 0;
  }
  .ent-slider{
    .slick-next,.slick-next:hover{
      top: auto !important;
      bottom: 0px;
      right: 35% !important;
    }
    .slick-prev,.slick-prev:hover{
      top: auto !important;
      bottom: 0px;
      left: 35% !important;
    }
  }
  .offer-slider{
    .slick-next,.slick-next:hover{
      top: auto !important;
      bottom: 0px;
      right: 35% !important;
    }
    .slick-prev,.slick-prev:hover{
      top: auto !important;
      bottom: 0px;
      left: 35% !important;
    }
  }
  .culinary-slider{
    .slick-next,.slick-next:hover{
      top: auto !important;
      bottom: 0px;
      right: 35% !important;
    }
    .slick-prev,.slick-prev:hover{
      top: auto !important;
      bottom: 0px;
      left: 35% !important;
    }
  }
  .testimonial-slider .slick-dots {
    position: relative;
    top: auto;
    right: auto;
    display: flex !important;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .sin-slider .slick-arrow{
    bottom: 30px !important;
  }
  .insta-section .insta-link{
    text-align: center;
  }

  .tag-wrap-inner{
    justify-content: center;
    padding-bottom: 20px;
  }
  .instagrid{
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 0;
    grid-row-gap: 0;
    .playbtn{
      width: 100% !important;
      height: 100% !important;
      img{
        height: 30px;
      }
    }
    div
    {
      width: 50%;
      height: 170px;
    }
    .div1
    {
      width: 100%;
      height: 300px;
    }
  }
  .prev-btn-modal img, .next-btn-modal img {
    height: 30px;
    width: 30px;
  }
}